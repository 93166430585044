<template>
  <st-box class="mt-24">
    <st-form-title :formTitle="$t(content.title)" :required="true" />
    <p class="text-xs leading-xs text-placeholder font-regular mt-4">
      {{ $t(content.description) }}
    </p>

    <st-form-title :formTitle="$t(content.name.title)" class="mt-32" />
    <input-text
      :name="content.name.name"
      :placeholder="$t(content.name.placeholder)"
      :rules="content.name.rules"
      :disabled="disabled"
    />

    <st-form-title :formTitle="$t(content.mobile.title)" class="mt-16" />
    <div class="flex gap-8">
      <dropdown
        rules="required"
        :name="content.mobile.nameDropdown"
        :options="options"
        :disabled="disabled"
        :dropdownProps="{
          size: 'lg',
          variant: 'line',
          distance: 0,
          offset: [0, 0],
          placement: 'bottom-start',
          class: 'w-[18rem] shrink-0'
        }"
        closeOnClick
        useTooltip
      >
        <template #default="{ option }">
          <s-dropdown-item :value="option.value">
            <s-tooltip
              arrow
              :content="`<p class='break-all'>${$t(option.label)}</p>`"
              :duration="0"
              :distance="4"
              useFlip
              flipOnUpdate
              placement="bottom-start"
              trigger="mouseenter focus"
              :theme="'studio-tooltip'"
              :zIndex="600"
              :allowHTML="true"
              :class="'truncate'"
            >
              <template #target>
                <span class="truncate tracking-[0] block">{{
                  `${option.extendedLabel} (${$t(option.label)})`
                }}</span>
              </template>
            </s-tooltip>
          </s-dropdown-item>
        </template>
        <template #dropdown-button="{ selectedOption }">
          {{ `${selectedOption.extendedLabel} (${$t(selectedOption.label)})` }}
        </template>
      </dropdown>
      <div class="w-full relative">
        <input-text
          :name="content.mobile.nameInput"
          :placeholder="$t(content.mobile.placeholder)"
          :rules="content.mobile.rules"
          :disabled="disabled"
        />
      </div>
    </div>

    <st-form-title :formTitle="$t(content.email.title)" class="mt-16" />
    <input-text
      :name="content.email.name"
      :placeholder="$t(content.email.placeholder)"
      :rules="content.email.rules"
      :disabled="disabled"
    />
  </st-box>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import InputText from '@/components/validation/input-text.vue';
import { MANAGER_TYPE, type ManagerType } from '@/constants/business-bank.const';
import type { FormOptionGroup } from '@/types/common/form.type';

const props = defineProps<{
  disabled?: boolean;
  options: FormOptionGroup<string>[];
  managerType: ManagerType;
}>();

const { t } = useI18n();

// 3.1-2-5 (9)
const REVIEW_CONTENT = {
  title: 'studio.business_bank_setting.individual.review_officer_info',
  description: 'studio.business_bank_setting.individual.review_officer_info_guide1',
  name: {
    name: 'individual.staffInfo.name',
    title: 'studio.business_bank_setting.individual.review_officer_info_name',
    placeholder: 'studio.business_bank_setting.individual.review_officer_info_name_place_holder',
    rules: {
      required: {
        value: true,
        message: 'studio.business_bank_setting.individual.review_officer_info_name_msg2'
      },
      max_length: {
        length: 50,
        message: t('studio.common.def_key.exceed', { length: 50 })
      },
      no_sp_character: true
    }
  },
  mobile: {
    title: 'studio.business_bank_setting.individual.review_officer_info_mobile_no',
    nameDropdown: 'individual.staffInfo.mobileCd',
    nameInput: 'individual.staffInfo.mobileNo',
    rules: {
      required: {
        value: true,
        message: 'studio.business_bank_setting.individual.review_officer_info_mobile_no_msg1'
      },
      number: t('studio.common.def_key.number_only_y'),
      max_length: {
        length: 15,
        message: t('studio.common.def_key.exceed', { length: 15 })
      },
      no_sp_character: true
    },
    placeholder:
      'studio.business_bank_setting.individual.review_officer_info_mobile_no_place_holder'
  },
  email: {
    name: 'individual.staffInfo.email',
    title: 'studio.business_bank_setting.individual.review_officer_info_email',
    placeholder: 'studio.business_bank_setting.individual.review_officer_info_email_place_holder',
    rules: {
      required: {
        value: true,
        message: 'studio.business_bank_setting.individual.review_officer_info_email_msg2'
      },
      email: 'studio.business_bank_setting.individual.review_officer_info_email_msg1',
      max_length: {
        length: 50,
        message: t('studio.common.def_key.exceed', { length: 50 })
      }
    }
  }
};

// 3.1-2-5 (10)
const CONTRACT_CONTENT = {
  title: 'studio.business_bank_setting.individual.settlement_officer_info',
  description: 'studio.business_bank_setting.individual.settlement_officer_info_guide1',
  name: {
    name: 'individual.settInfo.name',
    title: 'studio.business_bank_setting.individual.settlement_officer_info_name',
    placeholder:
      'studio.business_bank_setting.individual.settlement_officer_info_name_place_holder',
    rules: {
      required: {
        value: true,
        message: 'studio.business_bank_setting.individual.settlement_officer_info_name_msg2'
      },
      max_length: {
        length: 50,
        message: t('studio.common.def_key.exceed', { length: 50 })
      },
      no_sp_character: true
    }
  },
  mobile: {
    title: 'studio.business_bank_setting.individual.settlement_officer_info_mobile_no',
    nameDropdown: 'individual.settInfo.mobileCd',
    nameInput: 'individual.settInfo.mobileNo',
    rules: {
      required: {
        value: true,
        message: 'studio.business_bank_setting.individual.settlement_officer_info_mobile_no_msg1'
      },
      max_length: {
        length: 15,
        message: t('studio.common.def_key.exceed', { length: 15 })
      }
    },
    placeholder:
      'studio.business_bank_setting.individual.settlement_officer_info_mobile_no_place_holder'
  },
  email: {
    name: 'individual.settInfo.email',
    title: 'studio.business_bank_setting.individual.settlement_officer_info_email',
    placeholder:
      'studio.business_bank_setting.individual.settlement_officer_info_email_place_holder',
    rules: {
      required: {
        value: true,
        message: 'studio.business_bank_setting.individual.settlement_officer_info_email_msg2'
      },
      email: 'studio.business_bank_setting.individual.settlement_officer_info_email_msg1',
      max_length: {
        length: 50,
        message: t('studio.common.def_key.exceed', { length: 50 })
      }
    }
  }
};

const content = computed(() => {
  return props.managerType !== MANAGER_TYPE.CONTRACT ? REVIEW_CONTENT : CONTRACT_CONTENT;
});
</script>
